import axios from 'axios'

import { showSnackbar, snackbarTypes } from 'src/redux/snackbar'

export const GET_EMPLOYER_USERS_LOADING = 'employerUser/GET_EMPLOYER_USERS_LOADING'
export const GET_EMPLOYER_USERS_SUCCESS = 'employerUser/GET_EMPLOYER_USERS_SUCCESS'
export const GET_EMPLOYER_USERS_FAILURE = 'employerUser/GET_EMPLOYER_USERS_FAILURE'

export const getEmployerUsersLoading = () => ({ type: GET_EMPLOYER_USERS_LOADING })
export const getEmployerUsersSuccess = payload => ({ type: GET_EMPLOYER_USERS_SUCCESS, payload })
export const getEmployerUsersFailure = payload => ({ type: GET_EMPLOYER_USERS_FAILURE, payload })

const mapUserData = users =>
  users.map(user => ({
    ...user,
    email: user.user.email,
    firstName: user.user.firstName,
    lastName: user.user.lastName,
    employerId: user.employer.id,
  }))

export const fetchGetEmployerUsers = employerId => {
  return async dispatch => {
    dispatch(getEmployerUsersLoading())
    let path = '/api/employer_users'
    if (employerId) {
      path += `?employerId=${employerId}`
    }
    try {
      const response = await axios({
        url: path,
        method: 'GET',
      })
      dispatch(getEmployerUsersSuccess(response.data.employerUsers))
    } catch (error) {
      dispatch(showSnackbar('Error retrieving user data.', snackbarTypes.ERROR))
      dispatch(getEmployerUsersFailure(error.response))
    }
  }
}

const initialState = {
  loading: false,
  users: [],
}

export const usersReducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case GET_EMPLOYER_USERS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_EMPLOYER_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: mapUserData(payload),
      }

    case GET_EMPLOYER_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: payload,
      }
    default:
      return {
        ...state,
      }
  }
}
