import React from 'react'
import { Box, Button, Drawer, Typography, useTheme } from '@mui/material'

export const GenericDrawer = ({
  open,
  onClose,
  onSubmit,
  children,
  title = 'Drawer',
  submitLabel = 'Submit',
  cancelLabel = 'Cancel',
  width = '400px',
  disabled = false,
  ...rest
}) => {
  const theme = useTheme()
  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
      sx={{ '& .MuiDrawer-paper': { width } }}
      {...rest}
    >
      <Box component='form' onSubmit={onSubmit} sx={{ padding: '0px 0px 16px 0px' }}>
        <Box
          sx={{
            backgroundColor: theme.palette.secondary.light,
            marginBottom: '16px',
          }}
        >
          <Typography variant='h6' component='h2' sx={{ padding: '16px' }}>
            {title}
          </Typography>
        </Box>
        <Box sx={{ padding: '16px' }}>{children}</Box>
        <Box display='flex' justifyContent='flex-end' mt={2} sx={{ padding: '16px' }}>
          <Button onClick={onClose}>{cancelLabel}</Button>
          <Button type='submit' variant='contained' color='primary' disabled={disabled}>
            {submitLabel}
          </Button>
        </Box>
      </Box>
    </Drawer>
  )
}

export default GenericDrawer
