import axios from 'axios'
import { buildFormData } from 'src/redux/helpers'
import { showSnackbar, snackbarTypes } from 'src/redux/snackbar'
import { fetchGetEmployerUsers } from 'src/redux/users'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'
import { capitalizeWords } from 'common/helpers'

export const GET_EMPLOYER_USER_LOADING = 'employerUser/GET_EMPLOYER_USER_LOADING'
export const GET_EMPLOYER_USER_SUCCESS = 'employerUser/GET_EMPLOYER_USER_SUCCESS'
export const GET_EMPLOYER_USER_FAILURE = 'employerUser/GET_EMPLOYER_USER_FAILURE'

export const CREATE_EMPLOYER_USER_LOADING = 'employerUser/CREATE_EMPLOYER_USER_LOADING'
export const CREATE_EMPLOYER_USER_SUCCESS = 'employerUser/CREATE_EMPLOYER_USER_SUCCESS'
export const CREATE_EMPLOYER_USER_FAILURE = 'employerUser/CREATE_EMPLOYER_USER_FAILURE'

export const UPDATE_EMPLOYER_USER_LOADING = 'employerUser/UPDATE_EMPLOYER_USER_LOADING'
export const UPDATE_EMPLOYER_USER_SUCCESS = 'employerUser/UPDATE_EMPLOYER_USER_SUCCESS'
export const UPDATE_EMPLOYER_USER_FAILURE = 'employerUser/UPDATE_EMPLOYER_USER_FAILURE'
export const UPDATE_EMPLOYER_USER_ADMIN_LOADING = 'employerUser/UPDATE_EMPLOYER_USER_ADMIN_LOADING'
export const UPDATE_EMPLOYER_USER_ADMIN_SUCCESS = 'employerUser/UPDATE_EMPLOYER_USER_ADMIN_SUCCESS'
export const UPDATE_EMPLOYER_USER_ADMIN_FAILURE = 'employerUser/UPDATE_EMPLOYER_USER_ADMIN_FAILURE'

export const getEmployerUserLoading = () => ({ type: GET_EMPLOYER_USER_LOADING })
export const getEmployerUserSuccess = payload => ({ type: GET_EMPLOYER_USER_SUCCESS, payload })
export const getEmployerUserFailure = payload => ({ type: GET_EMPLOYER_USER_FAILURE, payload })

export const createEmployerUserLoading = () => ({ type: CREATE_EMPLOYER_USER_LOADING })
export const createEmployerUserSuccess = payload => ({
  type: CREATE_EMPLOYER_USER_SUCCESS,
  payload,
})
export const createEmployerUserFailure = payload => ({
  type: CREATE_EMPLOYER_USER_FAILURE,
  payload,
})

export const updateEmployerUserLoading = () => ({ type: UPDATE_EMPLOYER_USER_LOADING })
export const updateEmployerUserSuccess = payload => ({
  type: UPDATE_EMPLOYER_USER_SUCCESS,
  payload,
})
export const updateEmployerUserFailure = payload => ({
  type: UPDATE_EMPLOYER_USER_FAILURE,
  payload,
})
export const updateEmployerUserAdminLoading = () => ({ type: UPDATE_EMPLOYER_USER_ADMIN_LOADING })
export const updateEmployerUserAdminSuccess = () => ({
  type: UPDATE_EMPLOYER_USER_ADMIN_SUCCESS,
})
export const updateEmployerUserAdminFailure = payload => ({
  type: UPDATE_EMPLOYER_USER_ADMIN_FAILURE,
  payload,
})

const cleanEmployerUserData = employerUser => {
  let cleanedEmployerUser = { ...employerUser }
  if (cleanedEmployerUser.firstName) {
    cleanedEmployerUser.firstName = capitalizeWords(cleanedEmployerUser.firstName.trim())
  }
  if (cleanedEmployerUser.lastName) {
    cleanedEmployerUser.lastName = capitalizeWords(cleanedEmployerUser.lastName.trim())
  }
  if (cleanedEmployerUser.city) {
    cleanedEmployerUser.city = capitalizeWords(cleanedEmployerUser.city.trim())
  }
  if (cleanedEmployerUser.email) {
    cleanedEmployerUser.email = cleanedEmployerUser.email.trim().toLowerCase()
  }
  return cleanedEmployerUser
}

export const fetchGetEmployerUser = id => {
  return async dispatch => {
    dispatch(getEmployerUserLoading())
    try {
      const response = await axios({
        url: `/api/employer_users/${id}`,
        method: 'GET',
      })
      dispatch(getEmployerUserSuccess(response.data.employerUser))
    } catch (error) {
      dispatch(showSnackbar('Error retrieving user data.', snackbarTypes.ERROR))
      dispatch(getEmployerUserFailure(error.response.data))
    }
  }
}

export const fetchCreateEmployerUser = ({ data, employerId, onResponse }) => {
  return async dispatch => {
    dispatch(createEmployerUserLoading())
    try {
      const response = await axios({
        url: '/api/employer_users',
        method: 'POST',
        data: cleanEmployerUserData(data),
      })
      dispatch(fetchGetEmployerUsers(employerId))
      dispatch(showSnackbar('Successfully created new user', snackbarTypes.SUCCESS))
      onResponse()
      dispatch(createEmployerUserSuccess(response.data.employerUser))
    } catch (error) {
      dispatch(showSnackbar(error.response.data.errors.email[0], snackbarTypes.ERROR))
      dispatch(createEmployerUserFailure(error.response.data))
    }
  }
}

export const fetchUpdatePassword = data => {
  return fetchUpdateEmployerUser(
    data,
    'Successfully updated password.',
    'An error occurred updating your password.',
  )
}

export const fetchUpdateEmployerUser = (data, successMessage, failureMessage, onSuccess) => {
  return async dispatch => {
    dispatch(updateEmployerUserLoading())
    const successMsg = successMessage ? successMessage : 'Successfully updated profile.'
    const failureMsg = failureMessage ? failureMessage : 'An error occurred updating your profile'
    try {
      const response = await axios({
        url: `/api/employer_users/${data.id}`,
        method: 'PUT',
        data: buildFormData(cleanEmployerUserData(data)),
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      dispatch(showSnackbar(successMsg, snackbarTypes.SUCCESS))
      dispatch(updateEmployerUserSuccess(response.data.employerUser))
      dispatch(
        fetchTrackEvent({
          eventName: 'recruiter_profile_update_success',
          eventType: ProductAnalyticsEventTypes.ACTION,
          userId: response.data.employerUser.user.email,
          employerId: response.data.employerUser.employer.id,
        }),
      )
      if (onSuccess) {
        onSuccess.function(onSuccess.params)
      }
    } catch (error) {
      dispatch(showSnackbar(failureMsg, snackbarTypes.ERROR))
      dispatch(updateEmployerUserFailure(error.response.data))
      throw error
    }
  }
}

export const fetchUpdateEmployerUserAdmin = ({ data, onSuccess, isSuper }) => {
  return async dispatch => {
    dispatch(updateEmployerUserAdminLoading())
    const successMsg = 'Successfully updated user.'
    const failureMsg = 'An error occurred updating user'
    const employerId = isSuper ? null : data.employerId
    try {
      await axios({
        url: `/api/employer_users/${data.id}`,
        method: 'PUT',
        data: buildFormData(cleanEmployerUserData(data)),
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      dispatch(fetchGetEmployerUsers(employerId))
      onSuccess()
      dispatch(showSnackbar(successMsg, snackbarTypes.SUCCESS))
      dispatch(updateEmployerUserAdminSuccess())
    } catch (error) {
      dispatch(showSnackbar(failureMsg, snackbarTypes.ERROR))
      dispatch(updateEmployerUserAdminFailure(error.response))
    }
  }
}

const initialState = {
  loading: false,
  errors: [],
}

export const employerUserReducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case GET_EMPLOYER_USER_LOADING:
      return {
        ...state,
        ...payload,
        loading: false,
      }
    case GET_EMPLOYER_USER_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_EMPLOYER_USER_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_EMPLOYER_USER_SUCCESS:
    case UPDATE_EMPLOYER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: [],
        id: payload.id,
        firstName: payload.user.firstName,
        lastName: payload.user.lastName,
        isEmployerAdmin: payload.isEmployerAdmin,
        email: payload.user.email,
        phone: payload.user.phone,
        city: payload.user.city,
        state: payload.user.state,
        profileImage: payload.user.profileImage,
        about: payload.about,
        jobTitle: payload.jobTitle,
        employerName: payload.employer.name,
        employerId: payload.employer.id,
        showEmail: payload.user.showEmail,
        showPhone: payload.user.showPhone,
        employerImage: payload.employer.image,
        filters: payload.userFilters,
        emailVerifiedAt: payload.user.emailVerifiedAt,
      }

    case UPDATE_EMPLOYER_USER_FAILURE:
      return {
        ...state,
        loading: false,
        errors: payload.errors,
      }
    case UPDATE_EMPLOYER_USER_ADMIN_LOADING:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_EMPLOYER_USER_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case UPDATE_EMPLOYER_USER_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        errors: payload,
      }

    default:
      return {
        ...state,
      }
  }
}
