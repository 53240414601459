import React, { useState, useEffect } from 'react'
import { TextField } from '@mui/material'
import { useDispatch } from 'react-redux'
import { fetchCreateEmployer, fetchUpdateEmployer } from 'src/redux/employer'
import GenericDrawer from './GenericDrawer' // Adjust the path as necessary

const EmployerDrawer = ({ open, onClose, employer = null }) => {
  const dispatch = useDispatch()
  const [name, setName] = useState('')

  // Set the initial state when the drawer opens or the employer changes
  useEffect(() => {
    if (employer) {
      setName(employer.name || '')
    } else {
      setName('')
    }
  }, [employer])

  const handleSubmit = async event => {
    event.preventDefault()

    if (employer) {
      // Edit mode
      const updatedEmployer = {
        ...employer,
        name,
      }
      dispatch(fetchUpdateEmployer({ query: updatedEmployer, onResponse: onClose }))
    } else {
      // Add mode
      dispatch(fetchCreateEmployer({ name, onResponse: onClose }))
    }
  }

  const isEditMode = Boolean(employer)
  const title = isEditMode ? 'Edit Employer' : 'Add Employer'
  const submitLabel = isEditMode ? 'Update Employer' : 'Add Employer'

  return (
    <GenericDrawer
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={title}
      submitLabel={submitLabel}
      cancelLabel='Cancel'
      disabled={name === ''}
    >
      <TextField
        id='name'
        name='name'
        label='Employer Name'
        required
        fullWidth
        margin='normal'
        value={name}
        onChange={e => setName(e.target.value)}
      />
    </GenericDrawer>
  )
}

export default EmployerDrawer
